import {
  CloseOutlined,
  CodeSandboxCircleFilled,
  ConsoleSqlOutlined,
  MenuOutlined,
  SnippetsOutlined
} from "@ant-design/icons";
import { Button, Col, Divider, Drawer, Row, Tag, Typography, Switch, Flex, Menu } from "antd";
import { observer } from "mobx-react";

import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { NavLink, withRouter } from "react-router-dom";

import ConfigStore from "../../ConfigStore";
import {
  clone,
  cloneDeep,
  filter,
  find,
  findIndex,
  forEach,
  includes,
  intersectionWith,
  isEmpty,
  map,
  split
} from "lodash";

import GraphQlService from "../../GraphQlService";
import "../MainMenu.css";

import { themeConfig } from "../config";
import UserStore from "../../Users/UserStore";
import ViewGeneratorStore from "../../../ViewGenerator/ViewGeneratorStore";

const { Text } = Typography;

const PrintMenuElement = (element, location, closeMenuDrawer, t) => {
  if (element.children) {
    return (
      <Col
        sm={5}
        md={5}
        style={{ marginBottom: 24, width: 310, marginRight: 20 }}
        key={`menu_el_${element.name.props.children}`}
      >
        <Row gutter={16}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <span style={{ marginRight: 6, marginTop: 2 }}>{element.icon}</span>
            {element.name}
          </div>
          <Divider style={{ marginTop: 0, marginBottom: 6 }} />
        </Row>
        {element.children.map(e => PrintMenuElement(e, location, closeMenuDrawer))}
      </Col>
    );
  } else {
    return (
      <Row key={`menu_${element.name}`}>
        {element.link.includes("/help/") && (
          <a href={element.link}>
            <Button
              onClick={() => closeMenuDrawer()}
              style={
                element.link === ""
                  ? {
                      border: "0px solid transparent",
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      margin: 0,
                      padding: 0,
                      opacity: 0.4
                    }
                  : {
                      border: "0px solid transparent",
                      backgroundColor: "transparent",
                      margin: 0,
                      padding: 0,
                      boxShadow: "none"
                    }
              }
              disabled={element.link === "" ? true : false}
              ghost={element.link === "" ? true : false}
            >
              <Text>{element.name}</Text>
            </Button>
          </a>
        )}
        {!element.link.includes("/help/") && (
          <NavLink
            to={"/" + (element.link.includes("utils") ? "" : localStorage.getItem(`projectName`)) + element.link}
            style={{
              backgroundColor: element.link === location.pathname && "#f2f2f2",
              width: element.link === location.pathname && "100%"
            }}
          >
            <Button
              onClick={() => closeMenuDrawer()}
              type="text"
              disabled={element.link === "" ? true : false}
              ghost={element.link === "" ? true : false}
            >
              <Text>{element.name}</Text>
            </Button>
          </NavLink>
        )}
      </Row>
    );
  }
};

const MainMenu = ({ location, t }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [visible, setVisible] = useState(false);
  const [uiHubLinks, setUiHubConfig] = useState([]);
  const [menuStructure, setMenuStructure] = useState([]);
  const [assetDefinitions, setAssetDefinitions] = useState([]);
  const [defaultVisible, setDefaultVisible] = useState(localStorage.getItem(`superUserNavBar`) || false);

  useEffect(() => {
    loadMenu();
  }, [uiHubLinks, defaultVisible]);

  const sortByOrder = (a, b) => {
    return (a.order || Infinity) - (b.order || Infinity);
  };
  useEffect(() => {
    const graphQlService = new GraphQlService();
    graphQlService.get(`{ uiHubNavBarLinks { url assetDefinition { id symbol value } } }`).then(r => {
      setUiHubConfig(
        r.data.uiHubNavBarLinks.map(x => ({
          link: `/` + x.url,
          name: x.assetDefinition.value,
          id: x.assetDefinition.id
        }))
      );
    });
  }, []);
  const loadMenu = async () => {
    const menuData = JSON.parse(localStorage.getItem(`projectMenu`));
    if (isEmpty(menuData) || !menuData.length) {
      MenuStructure[0].children = uiHubLinks;
      setMenuStructure(() => MenuStructure);
    } else {
      const sortData = data => {
        return data
          .map(item => {
            item.children.forEach(child => (child.order = child.order + 1));
            return {
              ...item,
              children: item.children ? item.children.sort(sortByOrder) : []
            };
          })
          .sort(sortByOrder);
      };

      setMenuStructure(() =>
        sortData(menuData).map(data => {
          return {
            children: data.children,
            name: (
              <h4 style={{ marginTop: 2 }}>
                {data.key === "custom" || data.key.includes("custom") ? data.name : t(`mainMenu.${data.key}`)}
              </h4>
            )
            // name: <h4 style={{ marginTop: 2 }}>{data.key === "custom" ? data.name : t(`mainMenu.${data.key}`)}</h4>
          };
        })
      );
    }
  };

  const closeMenuDrawer = () => {
    setVisible(false);
    setIsClicked(false);
  };

  const toggleShowDrawer = () => {
    if (visible) {
      setIsClicked(false);
    } else {
      setIsClicked(true);
    }
    setVisible(!visible);
  };

  const currentPlan = localStorage.getItem(`plan`);
  const { currentTheme } = themeConfig();

  let MenuStructure = [
    /* {
      name: <h4 style={{ color: currentTheme === "dark" && "white", marginTop: 2 }}>{t("mainMenu.home")}</h4>,
      icon: <HomeOutlined style={{ color: currentTheme === "dark" && "white" }} />,
      label:
        (currentPlan === "bas" || currentPlan === "col" || currentPlan === "aut" || currentPlan === "caa") &&
        t("mainMenu.comingSoon"),
      children: [
        { name: t("mainMenu.myPriorities"), link: "/mypriorities" },
        { name: t("mainMenu.myWorkload"), link: "/myworkload" }
      ]
    } */ {
      name: (
        <h4 style={{ color: currentTheme === "dark" && "white", marginTop: 2 }}>{t("mainMenu.workflowRiskControl")}</h4>
      ),
      //icon: <BranchesOutlined style={{ color: currentTheme === "dark" && "white" }} />,
      label:
        (currentPlan === "bas" || currentPlan === "col" || currentPlan === "aut" || currentPlan === "caa") &&
        t("mainMenu.comingSoon"),
      children: uiHubLinks
      //[
      // { name: t("mainMenu.documents"), link: "/documents" },
      // { name: t("mainMenu.comments"), link: "/comments" },
      // { name: t("mainMenu.consents"), link: "/consents" },
      // { name: t("mainMenu.contracts"), link: "" },
      // { name: t("mainMenu.siteAccess"), link: "" },
      // { name: t("mainMenu.supplyChain"), link: "" },
      // { name: t("mainMenu.program"), link: "/program" },
      // ],
    },
    /*     {
      name: <h4 style={{ color: currentTheme === "dark" && "white", marginTop: 2 }}>{t("mainMenu.analyze")}</h4>,
      icon: <LineChartOutlined style={{ color: currentTheme === "dark" && "white" }} />,
      label:
        (currentPlan === "bas" || currentPlan === "col" || currentPlan === "aut" || currentPlan === "caa") &&
        t("mainMenu.comingSoon"),
      children: [
        { name: t("mainMenu.general"), link: "/general" },
        { name: t("mainMenu.workload"), link: "/workload" },
        { name: t("mainMenu.performance"), link: "/performance" },
        { name: t("mainMenu.subcontractors"), link: "/subcontractors" }
      ]
    }, */
    {
      name: <h4 style={{ color: currentTheme === "dark" && "white", marginTop: 2 }}>{t("mainMenu.collect")}</h4>,
      //icon: <SnippetsOutlined style={{ color: currentTheme === "dark" && "white" }} />,
      label:
        currentPlan !== "ent" &&
        currentPlan !== "col" &&
        (currentPlan === "pro" || currentPlan === "bas" || currentPlan === "aut") &&
        t("mainMenu.comingSoon"),
      children: [
        { name: t("mainMenu.myForms"), link: "/collect/forms" },
        { name: t("mainMenu.entries"), link: "/collect/entries" }
        //{ name: t("mainMenu.analytics"), link: "/collect/analytics" },
      ]
    },
    {
      name: <h4 style={{ color: currentTheme === "dark" && "white", marginTop: 2 }}>{t("mainMenu.automate")}</h4>,
      //icon: <SyncOutlined style={{ color: currentTheme === "dark" && "white" }} />,
      label:
        currentPlan !== "ent" &&
        currentPlan !== "aut" &&
        (currentPlan === "pro" || currentPlan === "bas" || currentPlan === "col") &&
        t("mainMenu.comingSoon"),
      children: [
        { name: t("mainMenu.automatedFlows"), link: "/automate/workflows" },
        { name: t("mainMenu.templates"), link: "/automate/templates" },
        { name: t("mainMenu.execution"), link: "/automate/workflows/executions" }
      ]
    },
    /*     {
      name: <h4 style={{ color: currentTheme === "dark" && "white", marginTop: 2 }}>{t("mainMenu.knowledgebase")}</h4>,
      icon: <BookOutlined style={{ color: currentTheme === "dark" && "white" }} />,
      children: [
        { name: t("mainMenu.enable"), link: "/help/?p=enable" },
        { name: t("mainMenu.control"), link: "/help/?p=control" },
        { name: t("mainMenu.analyze"), link: "/help/?p=analyze" },
        { name: t("mainMenu.collect"), link: "/help/?p=collect" },
        { name: t("mainMenu.automate"), link: "/help/?p=automate" },
      ],
    }, */ {
      name: <h4 style={{ color: currentTheme === "dark" && "white", marginTop: 2 }}>{t("mainMenu.control")}</h4>,
      //icon: <ControlOutlined style={{ color: currentTheme === "dark" && "white" }} />,
      children: [
        { name: t("mainMenu.myWorkflows"), link: "/workflows" },
        { name: t("mainMenu.riskAssessment"), link: "/risk-assessments" },
        { name: t("mainMenu.corporate"), link: "/corporate-repository" }
      ]
    }
  ];

  if (ConfigStore.platform === "mobile") {
    setMenuStructure([
      {
        name: t("mainMenu.collect"),
        icon: <SnippetsOutlined />,
        label: t("mainMenu.comingSoon"),
        children: [{ name: t("mainMenu.entries"), link: "/collect/entries" }]
      }
    ]);
    MenuStructure = [
      {
        name: t("mainMenu.collect"),
        icon: <SnippetsOutlined />,
        label: t("mainMenu.comingSoon"),
        children: [{ name: t("mainMenu.entries"), link: "/collect/entries" }]
      }
    ];
  }

  useEffect(() => {
    new GraphQlService().get(`{assetsDefinitions { id projectId symbol isVirtual isEvidence value  } }`).then(r => {
      let assets = r.data.assetsDefinitions.filter(x => !x.isVirtual && !x.isEvidence);
      setAssetDefinitions(assets);
    });
  }, []);

  return (
    <>
      <div
        style={{ boxShadow: "none", marginRight: 10, marginLeft: 16, marginTop: -7, height: 25 }}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          toggleShowDrawer();
        }}
      >
        {(isClicked || (isHovered && !isClicked)) && (
          <div style={{ width: 50 }}>
            <Button
              className={!isClicked && "menu-animation"}
              style={{ border: "1px solid #e0e0e0", display: "flex", alignItems: "center" }}
              type="text"
            >
              <MenuOutlined style={{ margin: "2px 8px 0px -10px" }} />
              {(isHovered || isClicked) && <span>{t("buttons.menu")}</span>}
            </Button>
          </div>
        )}
        {!isHovered && !isClicked && (
          <Button style={{ border: "1px solid transparent", display: "flex", alignItems: "center" }} type="text">
            <MenuOutlined style={{ margin: "2px -10px 0px" }} />
          </Button>
        )}
      </div>
      <Drawer
        onClose={closeMenuDrawer}
        open={visible}
        placement="top"
        height="auto"
        styles={{ content: { paddingTop: 60 }, mask: { backgroundColor: "transparent" } }}
        style={{ backgroundColor: "rgba(255, 255, 255, 0.8)", backdropFilter: "blur(7.7px)" }}
        closeIcon={<CloseOutlined style={{ marginTop: 40 }} />}
        closable={false}
      >
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginLeft: "15%" }}>
            <MenuContent
              location={location}
              closeMenuDrawer={closeMenuDrawer}
              assetDefinitions={assetDefinitions}
              setMenuStructure={setMenuStructure}
              menuStructure={menuStructure}
              MenuStructure={MenuStructure}
              uiHubLinks={uiHubLinks}
              defaultVisible={defaultVisible}
              setDefaultVisible={setDefaultVisible}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
};

const MenuContent = withTranslation()(
  observer(
    ({
      location,
      closeMenuDrawer,
      assetDefinitions,
      setMenuStructure,
      MenuStructure,
      menuStructure,
      t,
      uiHubLinks,
      defaultVisible,
      setDefaultVisible
    }) => {
      const onChange = checked => {
        setDefaultVisible(checked);
        localStorage.setItem(`superUserNavBar`, checked);
      };

      const isSystemAccess = child =>
        (child?.link && includes(child?.link, "/automate")) || includes(child?.link, "/collect");

      const configMenu = useCallback(() => {
        const assetIdsWithViewPermission = map(UserStore.securityAccess, (key, permission) => {
          if (key === "view") return filter(split(permission, "permission:"), v => !isEmpty(v))[0];
        });

        const matchingAssets = [];

        forEach(assetDefinitions, asset => {
          if (includes(assetIdsWithViewPermission, asset.id)) {
            const correspondingAsset = find(uiHubLinks, v => v.id === asset.id);
            matchingAssets.push({ ...asset, link: correspondingAsset?.link });
          }
        });

        const matchingAssetLinks = map(matchingAssets, x => x?.link);
        const newMenuStructure = { ...menuStructure };

        const linksToRemove = [];

        forEach(intersectionWith(assetIdsWithViewPermission, ["collect", "automate"]), perm =>
          matchingAssetLinks.push(perm)
        );

        forEach(newMenuStructure, menuItem => {
          forEach(menuItem.children, child => {
            if (UserStore.user?.version === -1 || assetIdsWithViewPermission.length === 0) return;
            if (child?.link && !includes(matchingAssetLinks, child.link) && !isSystemAccess(child))
              linksToRemove.push(child.link);

            if (isSystemAccess(child)) {
              const isCollect = includes(child.link, "/collect");
              const isAutomate = includes(child.link, "/automate");

              if (isCollect && !includes(matchingAssetLinks, "collect")) linksToRemove.push(child.link);
              if (isAutomate && !includes(matchingAssetLinks, "automate")) linksToRemove.push(child.link);
            }
          });
        });

        forEach(newMenuStructure, menuItem => {
          forEach(menuItem.children, child => {
            if (includes(linksToRemove, child.link)) {
              menuItem.children = filter(menuItem.children, x => x.link !== child.link);
            }
          });
        });

        setMenuStructure(filter(newMenuStructure, menuItem => menuItem.children.length > 0));
      }, [menuStructure]);

      useEffect(() => {
        configMenu();
      }, []);

      return (
        <Flex vertical={JSON.parse(localStorage.getItem(`isSuperUser`)) ? true : false} style={{ width: "100%" }}>
          <Row gutter={16} style={{ minWidth: "100%" }}>
            {JSON.parse(localStorage.getItem(`isSuperUser`)) && defaultVisible
              ? MenuStructure.map(element => PrintMenuElement(element, location, closeMenuDrawer, t))
              : menuStructure.map(element => PrintMenuElement(element, location, closeMenuDrawer, t))}
          </Row>
          {JSON.parse(localStorage.getItem(`isSuperUser`)) && (
            <Row>
              <Switch onChange={onChange} size="small" value={defaultVisible} />
            </Row>
          )}
        </Flex>
      );
    }
  )
);

export default withTranslation()(withRouter(MainMenu));
