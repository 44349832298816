import { every, isEmpty } from "lodash";
import { makeAutoObservable, toJS } from "mobx";

export class JqlStore {
  values = [];

  constructor() {
    makeAutoObservable(this);
  }

  // values = [{ key: 'field name', value: '' }]
  compare = (values, jql) => {
    this.values = values;
    var r = this.extractGroup(jql);

    return r.RuleResult;
  };

  extractGroup = model => {
    for (let i = 0; i < model.children1.length; i++) {
      if (model.children1[i].type == "group") this.extractGroup(model.children1[i]);
      else if (model.children1[i].type == "rule") model.children1[i] = this.extractRule(model.children1[i]);
    }
    if (model.children1.length > 0) {
      model.RuleResult = false;
      switch (model.properties && model.properties.conjunction ? model.properties.conjunction : "AND") {
        case "OR":
          if (model.children1.filter(x => x.RuleResult === true).length > 0) model.RuleResult = true;
          break;
        default:
          if (every(model.children1, x => x.RuleResult === true)) model.RuleResult = true;
          break;
      }
    }
    if (!model.RuleResult) model.RuleResult = false;
    return model;
  };

  extractRule = model => {
    model.RuleResult = false;
    var currentValue = this.values.find(x => x.id === model.properties.field);

    if (currentValue) currentValue = currentValue.value;

    if (!currentValue) return model;

    if (Object.prototype.toString.call(currentValue) === "[object Object]") {
      currentValue = currentValue.key ? currentValue.key : currentValue.value;
    }

    switch (model.properties.operator) {
      case "equal":
        model.RuleResult = currentValue === model.properties.value[0];
        break;
      case "not_equal":
        model.RuleResult = currentValue !== model.properties.value[0];
        break;
      case "like":
        model.RuleResult = currentValue.includes(model.properties.value[0]);
        break;
      case "not_like":
        model.RuleResult = !currentValue.includes(model.properties.value[0]);
        break;
      case "is_not_empty":
        model.RuleResult = !isEmpty(currentValue.toString());
        break;
      case "is_empty":
        model.RuleResult = isEmpty(currentValue.toString());
        break;
    }
    return model;
  };
}

export default new JqlStore();
