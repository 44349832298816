import { Checkbox, Form, Typography, Upload, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import ImageAnnotatorComponent from "../../../Common/ImageAnnotator/ImageAnnotatorComponent";
import FormSubmissionStore from "../../Submissions/FormSubmissionStore";
import CollectSubmissionCommonStore from "../../Submissions/view/FormSubmissionCommonStore";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import FormBuilderStore from "../../MyForms/FormBuilderStore";
import { EditOutlined, FileImageOutlined, UploadOutlined } from "@ant-design/icons";
import CollectFileUploadCommonComponent from "./common/CollectFileUploadCommonComponent";

const FormImageAnnotator = props => {
  const [value, setValue] = useState();
  const routerMatch = useRouteMatch();

  useEffect(() => {
    setValue(props.storeValue);
  }, []);

  useEffect(() => {
    console.log('on changed?', props)

    if (props.isSubmission && props.imageAnnotatorFile) {

      if (!props.storeValue) {
        console.log("new value", props);

        setValue(undefined);
        setTimeout(() => {

          var imgAnnotationValue = getValue(props);

          if (imgAnnotationValue.label) {
            var content = JSON.parse(imgAnnotationValue.label || "{}");
            setValue(content);
          }
        }, 100);
      } else {
        setTimeout(() => {
          var newValue = getValue(props);
          var newValue = JSON.parse((getValue(props)?.label || "{}") || "{}");

          console.log('new update?', toJS(props.storeValue), toJS(props.imageAnnotatorFile), toJS(FormSubmissionStore.values), newValue)
          if (newValue != props.storeValue) {
            setValue(undefined);
            setTimeout(() => {
              setValue(newValue);
            }, 100);
          }

        }, 100);
      }
    }
  }, [props]);

  const getValue = (props) => {
    var selectId = props.imageAnnotatorFile.split("_")[0];
    var fieldId = props.imageAnnotatorFile.split("_")[1];
    var select = FormSubmissionStore.values.find(x => x.id === selectId);
    if (!select) return;

    var selectDef = FormSubmissionStore.getField(selectId);
    if (!select || !select.value || select.value.length === 0) return;
    if (typeof select.value === "object") {
      if (select.value?.key) {
        var containerId = select.value.key.split("_")[2];
      } else {
        var containerId = select.value.value.split("_")[2];
      }
    } else {
      var containerId = select.value.split("_")[2];
    }

    var imageAnnotatorFileId = selectDef.externalItemsSourceConfig.assetDefinition.id;

    if (!FormSubmissionStore.externalDataValues[imageAnnotatorFileId]) return;

    return FormSubmissionStore.externalDataValues[imageAnnotatorFileId].find(
      x => x.id.includes(containerId) && x.id.includes(fieldId)
    );
  }

  const updateValue = newObj => {
    setValue(newObj);
    if (props.updateValue) props.updateValue(props.id, newObj);
  };

  if (!props.isSubmission)
    return (
      <div style={{ textAlign: "center" }}>
        {props.label && (
          <>
            {props.label}
            <br />
          </>
        )}
        <FileImageOutlined />
        <EditOutlined />
      </div>
    );

  console.log('fileImageAnnotator', toJS(props), toJS(value))
  return (
    <>
      {props.label && <>{props.label}</>}
      <br />

      {!value && props.allowUpload && (
        <CollectFileUploadCommonComponent
          onUpload={v => {
            updateValue({ url: v, annotations: JSON.stringify([]), claims: "" });
          }}
          fileUploadMode={props.fileUploadMode}
        />
      )}
      {!value && !props.allowUpload && <Typography.Text>-</Typography.Text>}
      {value && (
        <div style={{ minHeight: 200 }}>
          <ImageAnnotatorComponent
            submissionId={FormSubmissionStore.submissionId}
            url={value.url}
            currentAnnotations={JSON.parse(value.annotations || "[]")}
            onAnnotationsUpdated={v => {
              CollectSubmissionCommonStore.valueToUpdate = v.claims;
              updateValue({ ...value, annotations: JSON.stringify(v.annotations), claims: v.claims });
            }}
            allowClaim={props.allowClaim}
            allowModify={true}
          />
        </div>
      )}
    </>
  );
};
export const FormImageAnnotatorSettings = observer(props => {
  return (
    <>
      <Form.Item label="Allow upload">
        <Checkbox
          checked={FormBuilderStore.modifyInputColumn.allowUpload}
          onChange={e => props.setFieldValue("allowUpload", e.target.checked)}
        ></Checkbox>
      </Form.Item>

      <Form.Item label="Allow claim">
        <Checkbox
          checked={FormBuilderStore.modifyInputColumn.allowClaim}
          onChange={e => props.setFieldValue("allowClaim", e.target.checked)}
        ></Checkbox>
      </Form.Item>

      <Form.Item label="Require claim">
        <Checkbox
          checked={FormBuilderStore.modifyInputColumn.requireClaim}
          onChange={e => props.setFieldValue("requireClaim", e.target.checked)}
        ></Checkbox>
      </Form.Item>

      <Form.Item label="File upload mode">
        <Switch
          checked={FormBuilderStore.modifyInputColumn.fileUploadMode === 'multi'}
          onChange={e => props.setFieldValue("fileUploadMode", e === true ? 'multi' : 'single')}
          checkedChildren="Multi page"
          unCheckedChildren="Single page" />
      </Form.Item>
    </>
  );
});

export default FormImageAnnotator;
